const baseURL = 'https://qtuzld.com/'

const cookieDurationDays = 1
const clickIdCookieName = '_cityads_click_id'

function cookieOptions() {
  const config = useRuntimeConfig()
  const domain = config.public.env == 'production' ?
    "boxmagenta.com.br" : (config.public.env == 'homolog' ? "homolog.boxmagenta.com.br" : undefined)

  return {
    maxAge: 60 * 60 * 24 * cookieDurationDays,
    expires: new Date(Date.now() + 60 * 60 * 24 * cookieDurationDays * 1000),
    path: '/',
    domain: domain
  }
}

export interface CityAdsTrackSubscriptionCreatedRequest {
  orderId: string,
  orderTotal: number,
  currency: string,
  coupon?: string,
  basket?: string
}

export function setCityAdsCookies(clickId: string) {
  const clickIdCookie = useCookie(clickIdCookieName, cookieOptions())

  clickIdCookie.value = clickId
}

export function clearCityAdsCookies() {
  const clickIdCookie = useCookie(clickIdCookieName, cookieOptions())

  clickIdCookie.value = null
}

export async function trackCityAdsAttribution(request: CityAdsTrackSubscriptionCreatedRequest) {
  const config = useConfig()
  const campaignId = config.cityAds.campaignId
  const clickId = useCookie(clickIdCookieName, cookieOptions()).value

  if (!campaignId || !clickId) {
    return
  }

  try {
    var cityAdsPixel = document.createElement("script");
    cityAdsPixel.type = "text/javascript";
    cityAdsPixel.async = true;
    cityAdsPixel.src = `//qtuzld.com/track/${request.orderId}/ct/q1/c/${campaignId}?click_id=${clickId}&customer_type=novo&order_total=${request.orderTotal.toFixed(2)}&currency=${request.currency}&basket=${request.basket}&md=2`;

    var scriptTag = document.getElementsByTagName("script")[0];
    scriptTag.parentNode?.insertBefore(cityAdsPixel, scriptTag);
  } catch (error) {
    console.error('Failed to track CityAds subscription', error)
  }
}
