import { clearAwinCookies, setAwinCookies } from "./awin"
import { clearCityAdsCookies, setCityAdsCookies, trackCityAdsAttribution } from "./cityads"

export interface AffiliateSubscriptionConversion {
  subscriptionId: number,
  amount: number,
  couponCode?: string
}

export function onPageInit() {
  const route = useRoute()

  const awc = simpleLocationQueryValue(route.query.awc)
  const utmSource = simpleLocationQueryValue(route.query.utm_source)
  const clickId = simpleLocationQueryValue(route.query.click_id)
  const gclid = simpleLocationQueryValue(route.query.gclid)

  const hasAwinAttribution = awc && !utmSource && !gclid
  const hasCityAttribution = utmSource === 'cityads' && clickId && !awc && !gclid

  if (hasAwinAttribution) { // Awin
    setAwinCookies(awc)

    clearCityAdsCookies()
  } else if (hasCityAttribution) { // CityAds
    setCityAdsCookies(clickId)

    clearAwinCookies()
  } else if (utmSource || gclid) { // Other
    clearAwinCookies()
    clearCityAdsCookies()
  }
}

export function trackSubscriptionConversion(conversion: AffiliateSubscriptionConversion) {
  // TODO: Re-enable Awin tracking once we have the advertiserId and conversionUrl
  // trackAwinAttribution({
  //   amount: conversion.amount,
  //   currency: 'BRL',
  //   orderRef: `${conversion.subscriptionId}`,
  //   voucherCode: conversion.couponCode,
  //   isTest: false
  // })

  trackCityAdsAttribution({
    orderId: `${conversion.subscriptionId}`,
    orderTotal: conversion.amount,
    currency: 'BRL',
    coupon: conversion.couponCode,
  })
}
