const cookieDurationDays = 1

function cookieOptions() {
  const config = useRuntimeConfig()
  const domain = config.public.env == 'production' ?
    "boxmagenta.com.br" : (config.public.env == 'homolog' ? "homolog.boxmagenta.com.br" : undefined)

  return {
    maxAge: 60 * 60 * 24 * cookieDurationDays,
    expires: new Date(Date.now() + 60 * 60 * 24 * cookieDurationDays * 1000),
    path: '/',
    domain: domain
  }
}


export interface AwinTrackSubscriptionCreatedRequest {
  orderRef: string,
  amount: number,
  currency: string | null,
  voucherCode?: string,
  isTest: boolean
}

function awinAdvertiserId(): string | null {
  const config = useConfig()

  return config.awin.advertiserId
}

function awinConversionUrl(): string | null {
  const config = useConfig()

  return config.awin.conversionUrl
}

function awinCookieName(): string {
  const config = useConfig()

  return '_aw_m_' + config.awin.advertiserId
}

export function setAwinCookies(awin: string) {
  const awinCookie = useCookie(awinCookieName(), cookieOptions())

  awinCookie.value = awin
}

export function clearAwinCookies() {
  const awinCookie = useCookie(awinCookieName(), cookieOptions())

  awinCookie.value = null
}

export async function trackAwinAttribution(request: AwinTrackSubscriptionCreatedRequest) {
  const advertiserId = awinAdvertiserId()
  const conversionUrl = awinConversionUrl()
  const cks = useCookie(awinCookieName(), cookieOptions()).value

  if (!advertiserId || !conversionUrl || !cks) {
    return
  }

  const params = {
    a: advertiserId,
    b: request.amount.toFixed(2),
    cr: request.currency ?? 'BRL',
    c: request.orderRef,
    d: `DEFAULT:${request.amount.toFixed(2)}`,
    vc: request.voucherCode ?? '',
    t: request.isTest ? '1' : '0',
    ch: 'aw',
    cks: cks,
    l: window.location.href,
    tv: 2,
    tt: 'ia'
  }

  if (cks) {
    try {
      await $fetch(conversionUrl, {
        method: 'GET',
        params: params
      })
    } catch (error) {
      console.error('Failed to track Awin subscription', error)
    }
  }
}
