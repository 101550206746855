import { library, config } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faCircleQuestion,
  faCartShopping,
  faClock,
  faBagShopping,
  faPlus,
  faMinus,
  faStar as fasStar,
  faArrowLeft as fasArrowLeft,
  faChevronLeft as fasChevronLeft,
  faChevronRight as fasChevronRight,
  faChevronUp as fasChevronUp,
  faChevronDown as fasChevronDown,
  faClose as fasClose,
  faEdit as fasEdit,
  faEyeSlash as fasEyeSlash,
  faGift as fasGift,
  faGear,
  faExternalLinkAlt
} from '@fortawesome/free-solid-svg-icons'

import {
  faStar as farStar,
  faCopy as farCopy
} from '@fortawesome/free-regular-svg-icons'

// This is important, we are going to let Nuxt worry about the CSS
config.autoAddCss = false

// You can add your icons directly in this plugin. See other examples for how you
// can add other styles or just individual icons.
library.add(
  faExternalLinkAlt,
  faCartShopping,
  faCircleQuestion,
  faClock,
  faBagShopping,
  faPlus,
  faMinus,
  faGear,
  fasArrowLeft,
  fasChevronLeft,
  fasChevronRight,
  fasChevronUp,
  fasChevronDown,
  fasClose,
  fasEdit,
  fasEyeSlash,
  fasStar,
  fasGift,
  farStar,
  farCopy
)

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.component('fa', FontAwesomeIcon)
})
